.footer {
  padding-top: 70px;
  padding-bottom: 30px;
}

.footer .logo img {
  width: 60%;
  margin-bottom: 1rem;
}

.footer .logo p {
  color: var(--text-color);
  font-size: 1rem;
}

.social__link span a {
  text-decoration: none;
  color: var(--heading-color);
  font-size: 1.5rem;
}

.footer__link-title {
  color: var(--heading-color);
  margin-bottom: 1rem;
}

.footer__quick-links li a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 1.2rem;
  color: black;
}

.footer__quick-links a:hover {
  color: var(--secondary-color);
}

.footer__quick-links h6 span i {
  color: var(--secondary-color);
  font-size: 1.4rem;
}

.footer__quick-links h6 {
  margin-bottom: 1rem;
}

.footer__quick-links p {
  font-size: 1.2rem;
}

.copyright {
  color: var(--text-color);
  font-size: 1.2rem;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  /* Adjust padding and font size for smaller screens */
  .footer {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .footer .logo p {
    font-size: 0.9rem;
  }

  .social__link span a {
    font-size: 1.2rem;
  }

  .footer__link-title {
    font-size: 1.2rem;
  }

  .footer__quick-links li a {
    font-size: 1rem;
  }

  .footer__quick-links h6 span i {
    font-size: 1.2rem;
  }

  .footer__quick-links h6 {
    font-size: 1.2rem;
  }

  .footer__quick-links p {
    font-size: 1rem;
  }

  .copyright {
    font-size: 1rem;
  }
}
